.brochure-order {
  &-list {
    .action-button {
      min-width: 42px; // prevernt jumping

      border: none;
      background-color: transparent;

      appearance: none;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 15px;

        display: flex;

        min-width: 10rem; // as dropdown content
        height: 32px; // prevent jumping

        text-align: left;
        text-transform: none;

        color: $dark-blue;
        border: none !important;
        background-color: transparent !important;

        align-items: center;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none !important;
        }

        &::before {
          display: block;

          width: 15px;
          height: 10px;
          margin-right: 5px;

          content: "";

          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='SVGDoc' width='14' height='9' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:avocode='https://avocode.com/' viewBox='0 0 14 9'%3E%3Cdefs%3E%3C/defs%3E%3Cdesc%3EGenerated with Avocode.%3C/desc%3E%3Cg%3E%3Cg%3E%3Ctitle%3ELinie 13%3C/title%3E%3Cpath d='M12.26835,1.48779l-5.29978,5.58975' fill-opacity='0' fill='%23ffffff' stroke-dashoffset='0' stroke-linejoin='round' stroke-linecap='round' stroke-opacity='1' stroke='%2306293d' stroke-miterlimit='20' stroke-width='1.5'%3E%3C/path%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELinie 14%3C/title%3E%3Cpath d='M1.60547,1.48779l5.29978,5.58975' fill-opacity='0' fill='%23ffffff' stroke-dashoffset='0' stroke-linejoin='round' stroke-linecap='round' stroke-opacity='1' stroke='%2306293d' stroke-miterlimit='20' stroke-width='1.5'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

        &::after {
          border: none;
        }
      }

      .dropdown-menu {
        min-width: 10rem;
        padding: 0;

        border: none;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
      }

      .dropdown-item {
        font-size: 13px;

        padding-top: 10px;
        padding-bottom: 10px;

        &.active {
          font-weight: 700;

          color: #212529;
          background-color: transparent;

          &:hover,
          &:focus,
          &:active {
            background-color: #f8f9fa;
          }
        }
      }
    }
  }

  &-message {
    // from .company label
    label {
      font-size: 0.8rem;

      color: #a6adb4;
    }

    // from .company input
    input {
      width: 100%;
      // color: #a6adb4;

      border: none;
      border-bottom: solid 2px #a6adb4;
      outline: none;
      background-color: transparent;

      &:focus,
      &:active {
        border-bottom: solid 2px $dark-blue;
      }
    }
  }

  &-alert {
    position: relative;

    padding-right: 40px;

    &-toggler {
      position: absolute;
      top: 50%;
      right: 0;

      transform: translate(-50%, -50%);

      border: none;
      background-color: transparent;

      appearance: none;

      svg {
        line {
          stroke: $white;
        }
      }
    }

    &.alert {
      border-radius: 6px;

      .alert-message {
        font-size: 11px;
      }

      &-success {
        color: $white;
        background-color: #00ab00;
      }

      &-error {
        color: $white;
        background-color: #fc5c00;
      }
    }
  }

  &-send {
    float: right;
  }
}

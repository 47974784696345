.fonds-intro {
  .riskmesure {
    .indikator {
      font-weight: 600;

      color: $dark-grey;

      .active {
        padding: 10px 15px;

        color: $light-grey;
        border-radius: 50%;
        background-color: $dark-blue;
      }
    }

    .marker {
      position: absolute;
      z-index: 99;
      bottom: -25px;
      left: 50%;

      display: block;

      width: 18px;
      height: 10px;
      margin-left: -9px;

      background: $light-grey;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;

        width: 3px;
        height: 3px;
        margin-top: -1.5px;
        margin-left: -1.5px;

        content: "";

        border-radius: 50%;
        background: $dark-blue;
      }
    }

    .arrow-line {
      position: relative;

      margin-bottom: 1rem;

      .st {
        height: 20px;
        padding-top: 10px;

        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;

          display: block;
          //margin-top: - $arrow-height;

          height: 2px;

          content: "";

          background: $dark-blue;
        }
      }

      .arrow {
        display: inline-block;

        padding: 5px;

        border: solid $dark-blue;
        border-width: 0 2px 2px 0;
      }

      .right {
        margin-top: -15px;

        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      .left {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }

    .legend {
      font-size: $font-size-small;
      font-weight: 700;

      color: $dark-grey;
    }

    .value {
      font-size: $font-size-small-2;
      font-weight: 700;

      color: $dark-blue;
    }
  }

  .main-facts {
    .label {
      font-size: $font-size-small;
      font-weight: 700;

      color: $dark-grey;
    }

    .value {
      font-size: $font-size-small-2;
      font-weight: 700;
    }
  }

  .manager {
    .profileImage {
      width: 100%;
      height: 360px;

      border-radius: 10px;
      box-shadow: 0 0 5px 0 $standard-grey;
    }

    .label {
      font-size: $font-size-small;
      font-weight: 700;

      color: $dark-grey;
    }

    h4 {
      margin-bottom: $space-small;
    }

    .position {
      font-size: $font-size-small-2;
      font-weight: 700;

      color: $dark-grey;
    }
  }
}

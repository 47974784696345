.download-card-item {
  padding-right: $space-small-2;
  padding-left: $space-small-2;

  &-icon {
    width: 30px;
    height: 30px;
    padding-left: 0;
  }

  &-left-wrapper {
    padding-top: $space-small;
  }

  &-title {
    font-size: $font-size-regular;
    font-weight: 400;

    padding-left: $space-small-2;
  }

  .mail {
    margin-top: -6px;
  }

  hr {
    margin-top: -10px;
  }

  &-cta {
    padding-top: $space-small;

    a {
      padding-right: 8px;
    }

    .mail {
      margin-top: -7px;
    }
  }
}

.headNav {
  position: sticky;
  z-index: 99;
  top: 0;

  box-shadow: 0 0 5px 0 $dark-grey;
}

.navbar {
  height: 80px;

  background-color: $white;

  .funds-nav {
    width: 80%;

    .dropdown-menu {
      min-width: 250px;
    }
  }

  .nav-categoy {
    font-weight: 700;

    .nav-link {
      padding: 0 15px 0 15px;

      color: $dark-blue;
    }
  }

  .profile {
    font-weight: 700;

    .nav-link {
      padding-right: 0;

      color: $dark-blue;

      &::before {
        float: left;

        width: 20px;
        margin-right: 5px;

        content: url(../../Images/SVG/profile.svg);
      }
    }
  }

  .dropdown-menu {
    border-color: $standard-grey;
    background-color: $standard-grey;

    .dropdown-divider {
      overflow: hidden;

      height: 0;
      margin-right: 20px;
      margin-left: 20px;

      border-top: 1px solid $dark-grey;
    }

    .dropdown-item {
      font-weight: 600;

      padding: 10px 25px 10px 25px;

      text-transform: uppercase;

      opacity: 0.65;
      color: $dark-blue;
      background-color: $standard-grey;

      &.logout {
        &::after {
          float: right;

          width: 20px;
          margin-right: 10px;

          content: url(../../Images/SVG/logout.svg);

          opacity: 1;
        }
      }
    }
  }

  .container {
    padding-right: 0;
    padding-left: 0;
  }

  .row {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .navbar-collapse {
    margin-top: 5px;

    @media (max-width: 991px) {
      .profile-nav {
        margin-right: auto !important;
      }
    }

    @media (min-width: 992px) {
      .profile-nav {
        margin-left: auto !important;
      }
    }

    &.show {
      position: absolute;
      z-index: 99;
      top: 60px;
      left: 0;

      width: 120%;
      min-height: 100vh;
      margin-left: -20px;
      padding: 30px 0 0 30px;

      border-radius: 10px;
      background-color: $light-grey;

      .nav-item {
        font-size: $font-size-medium;

        margin: 10px;
      }

      .profile-nav {
        width: 80%;
      }
    }
  }

  .dropdown-toggle::after {
    content: none !important;
  }
}

.navbar-home {
  display: inline-flex;

  a {
    // width: 50px;
    svg {
      width: 35px;
      height: 35px;
      margin-top: 10px;
    }
  }

  .brand {
    height: 50px;
  }

  .customerLogo {
    padding-left: 50px;
  }
}

.subNav {
  display: flex;

  height: 55px;
  margin-top: -2px;

  background-color: $light-grey-2;

  align-items: center;

  .subLink {
    font-size: $font-size-small-2;
    font-weight: 700;

    margin: 20px 15px;

    text-decoration: none;

    color: $dark-blue;
  }
}

.card {
  padding: $space-large;

  border: none;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 $standard-grey;

  .card_footer {
    font-size: $font-size-small;
    font-weight: 600;

    color: $dark-grey;

    .card_label {
      font-weight: 700;
    }
  }
}

.meta {
  margin-top: 40px;
}

p {
  margin-bottom: $space-medium;
}

.imprint {
  a {
    text-decoration: none;

    color: $standard-blue;
  }
}

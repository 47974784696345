.floatingInput {
  position: relative;

  &__label {
    font-size: $font-size-small-2;

    position: absolute;
    top: 40px;
    left: $space-small;

    transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
    transform: translateY(0);
    pointer-events: none;

    color: #a6adb4;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }

  &__input {
    &::placeholder {
      color: transparent;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        color: $dark-grey;
      }
    }

    &:not(:placeholder-shown) {
      + .floatingInput__label {
        font-size: $font-size-small;

        transform: translateY(-15px);
      }
    }
  }
}

.fond_facts {
  .fond_fact {
    height: 100%;
    padding: $space-small-2 $space-large;

    border-bottom: 1px $dark-grey solid;

    .label {
      font-size: $font-size-small-2;
      font-weight: 700;

      color: $dark-grey;
    }

    .value {
      font-weight: 600;
    }
  }

  .pie_chart {
    height: 480px;
  }

  .pie_chart_legend {
    min-height: 470px;
  }
}

.custom-modal-style {
  height: 100%;
  margin: auto;

  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);

  opacity: 1 !important;

  &.show {
    transform: translateY(0%);
  }
}

.notification-body {
  min-height: 900px;
}

.modal-dialog {
  width: auto;
  max-width: none;
  margin: 20px 20px 0 20px;
}

.modal-header {
  border-bottom: none;
}

.modal-content {
  .copy {
    margin-left: unset;
  }
}

input.form-control {
  color: $dark-blue;
  border-color: $dark-blue;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;

  &:focus {
    border-color: $dark-blue;
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
  }
}

.text-light {
  color: $dark-blue !important;
}

legend {
  font-size: $font-size-small;
}

.panel {
  min-height: 480px;

  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 0 10px 0 $dark-grey;

  .login-error {
    font-weight: 700;

    color: darkred;
  }
}

.login {
  @media (max-width: 768px) {
    width: 90%;
  }

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (min-width: 1400px) {
    width: 33%;
  }

  @media (min-width: 1600px) {
    width: 33%;
  }
}

.password-reset-link {
  font-size: $font-size-small;
  font-weight: 700;

  margin-top: $space-medium;
  margin-bottom: -30px;

  text-transform: uppercase;

  color: $light-blue;

  &:hover {
    text-decoration: none;

    color: $light-blue;
  }
}

.password-reset {
  input,
  label {
    display: block;
  }

  label {
    font-size: $font-size-small;

    margin-bottom: -5px;

    color: $dark-grey;
  }

  input {
    font-weight: 600;

    padding: 0;
  }

  .btn {
    padding-right: $space-medium;
    padding-left: $space-medium;
  }
}

.event-list {
  .card {
    min-height: 370px;

    border: none;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 0 5px 0 $standard-grey;

    .image {
      border: 1px $standard-grey solid;
    }

    .row {
      height: 100%;
    }

    .time-label {
      font-size: $font-size-small-2;
      font-weight: 700;

      color: $dark-grey;
    }

    .item-teaser {
      white-space: pre-line;
    }
  }

  .event-item {
    img {
      max-width: 100%;
    }
  }

  .event-link {
    a {
      font-size: $font-size-small-2;
      font-weight: 700;

      text-decoration: none;

      color: $light-blue;
    }
  }
}

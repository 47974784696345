// @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700;800;900");

.font-color-standard-grey {
  color: $standard-grey;
}

.font-color-dark-grey {
  color: $dark-grey;
}

.font-size-small {
  font-size: $font-size-small;
  font-weight: bold;
}


@font-face {
  font-family: 'Nunito Sans';
  font-weight: 200;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-style: normal;

  src: url('../../Fonts/NunitoSans-Black.ttf') format('truetype');
}

.download-news-card {
  .card {
    height: 100%;
    min-height: 300px;
    padding: $space-large;

    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 $standard-grey;

    .subline {
      font-weight: 700;

      margin-top: $space-medium;
    }

    .copy {
      white-space: pre-line;

      ul li {
        margin-bottom: $space-medium;
      }
    }

    a {
      font-weight: 700;

      text-decoration: none;
      text-transform: uppercase;

      color: $light-blue;
    }

    .external-link::before {
      display: inline-block;

      width: 20px;
      height: 13px;

      content: " ";

      background-image: url("../../../Images/SVG/arrow_out.svg");
      background-repeat: no-repeat;
      background-size: 13px 13px;
    }
  }

  .documents {
    .label {
      font-weight: 700;
    }
  }

  .download-list {
    h3 {
      margin-bottom: $space-large;
    }

    .item {
      height: 65px;

      hr {
        border-top: 2px $light-grey-2 solid;
      }

      &-file {
        font-weight: 600;

        cursor: pointer;
        text-decoration: none;
        text-transform: none;

        color: $dark-blue;

        span::after {
          float: right;

          width: 20px;
          margin-top: -2px;
          margin-right: 10px;

          content: url(../../../Images/SVG/arrow_download.svg);
        }
      }
    }
  }
}

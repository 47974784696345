input,
label {
  display: block;
}

.password-change-modal {
  .modal-dialog {
    width: auto;
    max-width: none;
    margin: 20px 20px 0;
  }

  &-head {
    h4 {
      padding-top: $space-medium;
    }
  }

  .modal-content {
    min-height: 89vh;
  }

  &-input {
    flex: 1;

    margin-top: $space-xxlarge;
    padding-right: $space-small-2;

    label {
      font-size: $font-size-small-2;

      color: $dark-grey;
    }

    input {
      width: 100%;

      color: $dark-grey;
      border: none;
      border-bottom: solid 2px $dark-grey;
      outline: none;
    }
  }

  &-cta {
    padding-top: $space-xxlarge;
    padding-bottom: $space-xxlarge;

    button {
      span {
        padding: $space-medium;
      }
    }
  }
}

.required-hint {
  font-size: $font-size-small-2;

  margin-top: $space-small-2;

  color: $dark-grey;
}

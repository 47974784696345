.teaserImageText {
  .image {
    width: 100%;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 $dark-grey;
  }

  .download {
    .download_label {
      font-weight: 700;

      margin-right: 15px;
    }

    .btn {
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .video_modal {
    display: none;
  }
}

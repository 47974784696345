.invest-team {
  .card {
    margin-bottom: $space-medium;
    padding: $space-large;

    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 $standard-grey;

    h3 {
      margin-bottom: $space-medium;
    }

    .name {
      font-size: $font-size-regular;
      font-weight: bold;

      color: $dark-blue;
    }

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    .figureImg {
      height: 230px;

      img {
        border: 1px solid $standard-grey;
      }
    }

    .label {
      font-size: $font-size-regular;
      font-weight: 700;
    }

    .position {
      font-size: $font-size-small;
      font-weight: 700;

      color: $dark-grey;
    }

    .category {
      font-size: $font-size-medium;
      font-weight: bold;

      color: $dark-blue;
    }

    .categoryheader {
      font-size: $font-size-smedium;
      font-weight: bold;

      color: $dark-blue;
    }
  }
}

.download-card {
  height: 100%;

  h3 {
    color: $dark-blue;
  }

  h5 {
    color: $dark-grey;
  }

  &-section {
    margin: 0;
    padding: 0;
    padding-top: $space-small-2;

    h5 {
      color: $dark-blue;
    }
  }

  .mail-link {
    cursor: pointer;
  }
}

.pre-footer {
  margin-top: $space-xxlarge;

  a {
    opacity: 0.65;
    color: $dark-blue;
  }

  span {
    opacity: 0.65;
    color: $dark-blue;
  }
}

.footer {
  position: fixed;
  bottom: 0;

  width: 100%;

  color: $white;
  background-color: $light-blue;
  box-shadow: 0 0 5px 0 $dark-grey;

  a {
    font-size: $font-size-small;
    font-weight: 700;

    margin-right: 30px;

    text-transform: uppercase;

    color: $white;
  }

  .row {
    height: 70px;
  }

  .service-links {
    font-size: $font-size-small-2;
    font-weight: 700;

    margin-left: 30px;

    text-transform: uppercase;

    a {
      font-size: $font-size-small-2;

      text-decoration: none;
    }

    .embeddedServiceHelpButton {
      position: unset;

      display: contents;

      .helpButton {
        position: unset;

        .helpButtonEnabled {
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;

          text-decoration: none;
          text-transform: uppercase;

          color: white;
          border: none;
          background-color: $light-blue;

          &::before {
            background-color: transparent;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

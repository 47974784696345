.cards {
  .card {
    height: 100%;
    min-height: 300px;
    padding: $space-large;

    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 $standard-grey;

    .subline {
      font-weight: 700;

      color: $dark-grey;
    }

    .copy {
      ul li {
        margin-bottom: $space-medium;
      }
    }
  }

  .row.is-flex {
    display: flex;

    flex-wrap: wrap;
  }

  .row.is-flex > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  /*
  * And with max cross-browser enabled.
  */
  .row.is-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .row.is-flex > [class*="col-"] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  }
}

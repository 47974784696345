table {
  width: 100%;
}

table thead {
  border-bottom: 2px $standard-grey solid;

  tr {
    height: 60px;
  }
}

table tbody {
  border-bottom: 2px $standard-grey solid;

  tr {
    height: 60px;

    border-bottom: 1px $standard-grey solid;

    &:last-child {
      border-bottom: none;
    }
  }
}

.downloads-switch {
  position: fixed;
  z-index: 110;
  top: calc(100% / 2 - 75px);
  right: 0;

  display: flex;
  flex-direction: column;

  width: 80px;
  height: 105px;

  cursor: pointer;

  border-radius: 10px 0 0 10px;
  background: $dark-blue;

  justify-content: center;
  align-items: center;

  &-icon {
    height: 70px;
  }
}

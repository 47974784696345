ul {
  margin-left: -20px;

  list-style: none; /* Remove default bullets */
}

ul li::before {
  font-weight: bolder; /* If you want it to be bold */

  display: inline-block; /* Needed to add space between the bullet and the text */

  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */

  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */

  color: $light-blue; /* Change the color */
}

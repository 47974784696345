// base-configuration

body {
  font-family: "Nunito Sans", sans-serif;

  min-height: 100vh;

  color: $dark-blue;
  background-color: $light-grey;
}

.container {
  max-width: 1600px;
}

.separator {
  border-bottom: $light-grey-2 2px solid;
}

.copy {
  a {
    font-size: $font-size-small-2;
    font-weight: 700;

    text-decoration: none;
    text-transform: uppercase;

    color: $light-blue;
  }

  .internal-link::before {
    display: inline-block;

    width: 20px;
    height: 13px;

    content: " ";

    background-image: url("../../Images/SVG/arrow_right.svg");
    background-repeat: no-repeat;
    background-size: 13px 13px;
  }

  .external-link::before {
    display: inline-block;

    width: 20px;
    height: 13px;

    content: " ";

    background-image: url("../../Images/SVG/arrow_out.svg");
    background-repeat: no-repeat;
    background-size: 13px 13px;
  }
}

.embeddedServiceHelpButton {
  z-index: 2 !important;

  .helpButton {
    bottom: 80px !important;
    border-radius: 50% !important;
    height: 55px !important;
    z-index: 2 !important;
    width: 55px !important;
    right: 20px !important;

    .uiButton {
      background: #008cd6 !important;
      box-shadow: none !important;
      min-width: 55px !important;
      font-size: 25px !important;
      height: 55px !important;
      border-radius: 50% !important;

      &:hover,
      &:focus,
      &:active {
        &::before {
          border-radius: 50% !important;
        }
      }

      .helpButtonLabel {
        display: none !important;
      }

      .embeddedServiceIcon {
        display: block !important;
        margin-right: 0 !important;
        margin-top: 2px;
      }
    }
  }
}

.embeddedServiceSidebarMinimizedDefaultUI {
  &.helpButton {
    bottom: 80px !important;
    border-radius: 50% !important;
    height: 55px !important;
    z-index: 2 !important;
    right: 20px !important;
    width: 55px !important;
    background: #008cd6 !important;
    box-shadow: none !important;
    border: none !important;
    min-width: auto !important;

    &:hover,
    &:focus,
    &:active {
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 23px;
        background-color: #000;
        opacity: 0.2;
        pointer-events: none;
        border-radius: 50% !important;
      }
    }
  }

  .embeddedServiceIcon {
    margin-right: 0 !important;
    font-size: 31px;
  }

  .minimizedText {
    display: none !important;
  }
}

.embeddedServiceSidebarFeature ul li:before {
  display: none;
}

.warning {
  color: $orange;
}

.sample {
  .bullet {
    margin: $space-small 0 $space-small;

    span {
      margin-left: $space-medium;
    }

    span::before {
      font-weight: bolder; /* If you want it to be bold */

      display: inline-block; /* Needed to add space between the bullet and the text */

      width: 1em; /* Also needed for space (tweak if needed) */
      margin-top: -2px;
      margin-left: -1em; /* Also needed for space (tweak if needed) */

      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */

      color: $light-blue; /* Change the color */
    }
  }

  .example {
    margin-top: $space-medium;
    padding-right: $space-medium;
    padding-left: $space_medium;

    table thead tr th:nth-of-type(10) {
      color: $light-blue;
    }

    table tbody tr:nth-of-type(2) td:nth-of-type(10) {
      color: $light-blue;
    }

    table tbody tr:nth-of-type(3) td:nth-of-type(10) {
      color: $light-blue;
    }

    table tbody tr:nth-of-type(1) {
      color: $dark-grey;
    }
  }
}

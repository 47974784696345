.sidebar {
  .card {
    height: 140px;

    border: none;
    border-radius: 5px;
    background-color: $light-grey-2;
    box-shadow: 0 0 10px 0 $dark-grey;

    .card-image {
      height: 70px;

      img {
        max-width: 90%;
        max-height: 70px;
      }
    }

    .card-separator {
      height: 5px;
      margin-bottom: 5px;

      border-bottom: $dark-grey 2px solid;
    }

    .card-link {
      height: 60px;
    }

    a {
      font-size: $font-size-small;
      font-weight: 900;

      text-transform: uppercase;

      color: $light-blue;
    }
  }
}

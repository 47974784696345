.back-link {
  font-weight: 700;

  margin: 20px 0 20px 0;

  text-transform: uppercase;

  opacity: 0.65;

  a {
    text-decoration: none;

    opacity: 0.65;
    color: $dark-blue;
  }
}

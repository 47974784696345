.card-type-one {
  height: 250px;
  margin-bottom: $space-large;

  border-radius: 10px;
  background-color: $card-blue;
  box-shadow: 0 0 10px 0 $dark-grey;

  .card-image {
    height: 130px;

    img {
      height: 30px;
    }
  }

  .card-label {
    height: 50px;
  }

  .card-link {
    height: 70px;
  }
}

.card-type-second {
  display: flex;
  flex-direction: column;

  height: 250px;
  margin-bottom: $space-large;
  padding-top: 25px;

  border-radius: 10px;
  background-color: $card-grey;
  box-shadow: 0 0 10px 0 $dark-grey;

  justify-content: space-between;

  .card-label {
    height: 50px;
  }

  .card-link {
    height: 70px;
    padding-bottom: 30px;
  }

  .checkboxes {
    height: 75px;
  }

  .checkbox-row {
    margin-bottom: 10px;
  }

  .checkbox-title {
    font-weight: bold;

    padding-left: 12px;
  }

  .newsletter-selection {
    padding-left: 15px;
  }
}

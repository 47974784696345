.dropzone {
  &-container {
    position: relative;

    display: flex;

    height: 350px;

    border: 1px dashed #a6adb4;

    align-items: center;
    justify-content: center;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;

      content: "";
      transition: opacity 0.15s ease-in-out;
      pointer-events: none;

      opacity: 0;
      background-color: #fff;
    }
    &.active {
      &::after {
        opacity: 0.5;
      }
    }
  }

  &-message {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    span {
      margin: 2px 0 10px;
    }
  }

  &-input {
    display: none;
  }

  &-actions {
    display: flex;

    justify-content: flex-end;
    align-items: center;
  }

  .file-name {
    word-break: break-all;
  }

  .file-remove {
    border: none;
    background-color: transparent;

    appearance: none;
  }

  .remove-elements {
    display: flex;

    color: #008cd6;
    border: none;
    background-color: transparent;

    appearance: none;
    align-items: center;
    justify-content: center;
  }
}

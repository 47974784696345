.spinner {
  position: fixed;
  z-index: 30001;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  background-color: rgba(75, 75, 75, 0.4);

  &.small {
    position: initial !important;

    background-color: transparent;
  }

  &.show {
    display: block;
  }

  & > div {
    position: absolute;
    top: 42%;
    left: 50%;

    img {
      margin-left: -41px;
    }
  }
}

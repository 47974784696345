.backdrop {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.7);
}

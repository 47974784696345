.service-information {
  @media (max-width: 1440px) {
    display: none;
  }
}

.page-headline {
  .subline {
    font-size: $font-size-small-2;
    font-weight: 700;

    color: $dark-grey;
  }
}

.side-drawer {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;

  width: 35%;
  min-width: 200px;
  max-width: 370px;
  height: 100%;

  transition: transform 0.3s ease-out;
  transform: translateX(100%);

  background: $dark-blue;

  p {
    color: white;
  }
}

.open {
  transform: translateX(0);
}

.dl {
  &-header {
    padding: $space-medium;

    border-bottom: 2px solid rgba(166, 173, 180, 0.25);

    &-close {
      cursor: pointer;
    }

    h3 {
      font-weight: 600;

      margin-top: 15px;
      padding-left: $space-small-2;

      color: $white;
    }
  }

  h5 {
    font-weight: 400;

    padding-top: $space-medium;
    padding-bottom: $space-medium;

    color: $white;
  }

  &-list-element {
    padding-top: $space-small-2;

    &-title {
      font-size: $font-size-small-2;
      font-weight: 600;

      color: $white;
    }

    &-close {
      height: 15px;

      cursor: pointer;
    }
  }

  &-list-empty {
    color: $white;
  }

  &-seperator {
    margin-top: 0;

    border-width: 2px;
    border-color: $dark-grey;
  }

  &-item-seperator {
    margin-top: -5px;

    border-width: 2px;
    border-color: $dark-grey;
  }

  &-list-footer {
    padding-top: $space-medium-2 * 3;

    .remove-elements {
      font-size: $font-size-small;

      margin: $space-medium;

      color: $light-blue;
    }

    h5 {
      margin: 0;
      padding-left: $space-small;

      color: $light-blue;
    }

    &-btn-cta {
      width: 100%;
      margin-top: $space-medium;
      margin-bottom: $space-small-2;
      margin-left: 0;

      text-align: center;

      &-text {
        margin-left: $space-small;
      }
    }

    &-delete-all {
      cursor: pointer;
    }

    .download-img {
      margin-bottom: -10px;
    }
  }
}

.spacer {
  flex-grow: 1;
}

.download-email-modal {
  .modal-dialog {
    width: auto;
    max-width: none;
    margin: 20px 20px 0;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    min-height: 99vh;
  }

  &-head {
    h4 {
      padding-top: $space-medium;
    }
  }

  &-input {
    flex: 1;

    padding-top: $space-xxlarge;

    border-width: 0 0 2px;
    border-color: $dark-grey;
    outline: 0;

    &:focus {
      border-color: $dark-blue;
    }
  }

  .name {
    margin-right: $space-small-2;
  }

  .lastname {
    margin-left: $space-small-2;
  }

  &-textarea {
    flex: 1;

    height: 12rem;
    margin-top: $space-xxlarge;
    padding: 0;

    border: none;
    border-bottom: 2px solid $dark-grey;
    outline: none;
  }

  &-info {
    padding-top: $space-small-2;

    color: $dark-grey;
  }

  &-attachment {
    &-head {
      padding-top: $space-xlarge;
    }

    img {
      height: 70px;
    }

    h4 {
      font-weight: 300;

      padding-left: $space-medium;
    }

    &-copy {
      padding-top: $space-xlarge;

      span {
        margin-left: $space-medium;
      }
    }

    &-checkbox {
      padding-left: -25px;
    }
  }

  &-selection {
    padding-top: $space-xxlarge;
    padding-bottom: $space-xlarge;
  }

  &-option {
    font-weight: 700;

    padding-top: $space-large;
  }

  &-required-hint {
    font-size: $font-size-small-2;
    font-weight: 600;

    color: $dark-grey;
  }

  &-confirm {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  button {
    text-transform: uppercase;
  }

  &-submit {
    padding-right: $space-small-2;

    .row {
      padding-right: 24px;
      padding-left: 16px;
    }
  }

  &-error {
    font-size: $font-size-regular;
    font-weight: 700;

    color: red;
  }
}

.radio {
  display: inline-block;

  margin-right: 30px;

  &__label {
    font-size: $font-size-small-2;

    display: flex;

    color: $dark-grey;

    align-items: center;

    &::before {
      position: relative;

      display: inline-block;

      width: 30px;
      height: 30px;
      margin-right: 8px;

      content: "";

      border: 1px solid $dark-blue;
      border-radius: 50%;
    }
  }

  &__input {
    width: 0;
    height: 0;

    opacity: 0;

    &:checked {
      + .radio__label {
        color: $dark-blue;

        &::before {
          border-color: $light-blue !important;
          background-color: $light-blue !important;
          box-shadow: inset 0 0 0 3px $white;
        }
      }
    }
  }
}

.mt-small {
  margin-top: $space-small;
}

.mt-medium {
  margin-top: $space-medium;
}

.mt-medium-2 {
  margin-top: $space-medium-2;
}

.mt-large {
  margin-top: $space-large;
}

.mt-xlarge {
  margin-top: $space-xlarge;
}

.mt-xxlarge {
  margin-top: $space-xxlarge;
}

.mt-xxxlarge {
  margin-top: $space-xxxlarge;
}

.mt-inpage {
  margin-top: 200px;
}

.mr-small {
  margin-right: $space-small;
}

.mr-small-2 {
  margin-right: $space-small-2;
}

.mr-medium {
  margin-right: $space-medium;
}

.mr-large {
  margin-right: $space-large;
}

.mb-small {
  margin-bottom: $space-small;
}

.mb-small-2 {
  margin-bottom: $space-small-2;
}

.mb-medium {
  margin-bottom: $space-medium;
}

.mb-medium-2 {
  margin-bottom: $space-medium;
}

.mb-large {
  margin-bottom: $space-large;
}

.mb-xlarge {
  margin-bottom: $space-xlarge;
}

.mb-xxlarge {
  margin-bottom: $space-xxlarge;
}

.mb-xxxlarge {
  margin-bottom: $space-xxxlarge;
}

.ml-small {
  margin-left: $space-small;
}

.ml-small-2 {
  margin-left: $space-small-2;
}

.ml-medium {
  margin-left: $space-medium;
}

.ml-medium-2 {
  margin-left: $space-medium-2;
}

.ml-large {
  margin-left: $space-large;
}

.ml-xlarge {
  margin-left: $space-xlarge;
}

.ml-xxlarge {
  margin-left: $space-xxlarge;
}

.pd-medium {
  padding: $space-medium;
}

.pd-large {
  padding: $space-large;
}

.pd-xlarge {
  padding: $space-xlarge;
}

.pr-small {
  padding-right: $space-small;
}

.pr-medium {
  padding-right: $space-medium;
}

.pr-xlarge {
  padding-right: $space-xlarge;
}

.pl-small {
  padding-left: $space-small;
}

.pl-medium {
  padding-left: $space-medium;
}

.pl-xxlarge {
  padding-left: $space-xxlarge;
}

.pt-large {
  padding-top: $space-large;
}

.pb-small {
  padding-bottom: $space-small;
}

.pb-large {
  padding-bottom: $space-large;
}

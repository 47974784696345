// real headlines
h1 {
  font-size: $font-size-xlarge;
  font-weight: 700;
}

h2 {
  font-size: $font-size-double;
  font-weight: 700;
}

h3 {
  font-size: $font-size-medium;
  font-weight: 700;

  .h3-white {
    color: $white;
  }

  .h3-dark {
    color: $dark-blue;
  }
}

h4 {
  font-size: $font-size-smedium;
  font-weight: 700;
}

h5 {
  font-size: $font-size-regular;
  font-weight: 600;
}

// pseudo headlines via class
.h5 {
  font-size: $font-size-regular;
  font-weight: 600;

  margin-bottom: $space-medium;

  &::after {
    content: "\A";
    white-space: pre;
  }
}

.contact_person {
  padding: 30px;

  border-radius: 10px;
  background-color: $light-grey-2;
  box-shadow: 0 0 5px 0 $standard-grey;

  .label {
    font-size: $font-size-small-2;
    font-weight: 700;

    color: $dark-grey;
  }

  .service-item {
    margin-bottom: $space-large;

    a {
      font-size: $font-size-smedium;
      font-weight: 700;

      text-decoration: none;

      color: $light-blue;
    }
  }

  .image {
    img {
      border: 1px $standard-grey solid;
    }

    .label {
      display: block;

      &.name {
        font-size: $font-size-medium;
        font-weight: 700;

        color: $dark-blue;
      }
    }
  }

  img {
    width: 100%;
    //max-width: 260px;
    height: auto;
  }
}

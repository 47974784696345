$white: #fff;
$light-grey: #f7f6f4;
$light-grey-2: #f0eeea;
$standard-grey: #cbd0d3;
$dark-grey: #a6adb4;

$green: #44a71f;
$orange: #eb6617;
$pink: #c71a88;
$violet: #7d57a1;

$light-blue: #008cd6;
$standard-blue: #17577f;
$card-blue: #108cd6;
$card-grey: #f0eeea;
$grey-blue: #3d5464;
$dark-blue: #06293d;

.detail {
  &-head {
    padding-top: $space-xxlarge;
  }

  &-title {
    padding-top: $space-medium;
  }

  &-date {
    font-weight: 700;
  }

  &-body {
    font-family: "Nunito Sans", sans-serif;

    padding: $space-large 0 $space-large 0;

    ul,
    ol {
      padding-top: $space-medium-2;
      padding-bottom: $space-medium-2;
    }

    h4 {
      padding: $space-small 0 $space-small 0;
    }
  }

  .last-item {
    padding: $space-large $space-medium 0 0;

    .card {
      padding: $space-large;

      &:hover {
        cursor: pointer;
      }
    }

    &-date {
      font-size: $font-size-small;
      font-weight: 700;

      padding-bottom: -20px;
    }

    &-title {
      font-size: $font-size-small-2;
      font-weight: 700;

      display: -webkit-box;
      overflow: hidden;

      margin-top: -10px;

      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    a {
      text-decoration: none;

      color: $grey-blue;
    }
  }

  .next-item {
    padding: $space-large 0 0 $space-medium;

    .card {
      padding: $space-large;

      &:hover {
        cursor: pointer;
      }
    }

    &-date {
      font-size: $font-size-small;
      font-weight: 700;

      padding-bottom: -20px;
    }

    &-title {
      font-size: $font-size-small-2;
      font-weight: 700;

      display: -webkit-box;
      overflow: hidden;

      margin-top: -10px;

      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    a {
      text-decoration: none;

      color: $grey-blue;
    }
  }

  &-arrow-svg {
    height: 45px;
    margin: 8px;

    color: $card-blue;
  }

  button {
    margin-top: $space-xxlarge;
  }
}

.newsletter-modal {
  .modal-dialog {
    width: auto;
    max-width: none;
    margin: 20px 20px 0;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    min-height: 99vh;
  }

  &-head {
    h4 {
      padding-top: $space-medium;
    }
  }

  &-input {
    width: 100%;
    padding-top: $space-xxlarge;

    border-width: 0 0 2px;
    border-color: $dark-grey;
    outline: 0;

    &:focus {
      border-color: $dark-blue;
    }
  }

  &-selection {
    padding-top: $space-xxlarge;
    padding-bottom: $space-xlarge;
  }

  &-option {
    font-weight: 700;

    padding-top: $space-large;
  }

  &-required-hint {
    font-size: $font-size-small-2;
    font-weight: 600;

    color: $dark-grey;
  }

  &-confirm {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  button {
    text-transform: uppercase;
  }

  &-error {
    font-size: $font-size-regular;
    font-weight: 700;

    color: red;
  }
}

input,
label {
  display: block;
}

.profile-section {
  margin-top: $space-xlarge;
  margin-bottom: $space-xlarge;

  h3 {
    padding-bottom: $space-xlarge;
  }

  &-input {
    flex: 1;

    padding-right: $space-small-2;

    label {
      font-size: $font-size-small-2;

      color: $dark-grey;
    }

    input {
      width: 100%;

      color: $dark-grey;
      border: none;
      border-bottom: solid 2px $dark-grey;
      outline: none;
    }
  }

  button,
  .link {
    margin-top: $space-large;
    margin-right: $space-small;
  }
}

.company {
  label {
    font-size: $font-size-small-2;

    color: $dark-grey;
  }

  input {
    width: 100%;

    color: $dark-grey;
    border: none;
    border-bottom: solid 2px $dark-grey;
    outline: none;
  }
}

.company_logo {
  .brand {
    max-height: 60px;
  }
}

.personal-data {
  padding-bottom: $space-xxlarge;
}

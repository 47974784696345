.image-card {
  height: 250px;
  margin-bottom: $space-large;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 $dark-grey;

  .card-image {
    height: 130px;

    img {
      height: 30px;
    }
  }

  .card-label {
    height: 50px;
  }

  .card-link {
    height: 70px;
  }

  .btn {
    min-width: 160px;
  }
}

.news-teaser-card {
  .teaser {
    .date {
      font-size: $font-size-small;

      color: $dark-grey;
    }

    .teaser-text {
      font-size: $font-size-small-2;
      font-weight: 600;
    }

    .deep-link {
      font-size: $font-size-small;

      a {
        font-weight: 800;

        text-decoration: none;
        text-transform: uppercase;

        color: $light-blue;
      }

      svg {
        margin-top: -1px;
      }
    }
  }
}

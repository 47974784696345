.news-list {
  .card {
    min-height: 270px;

    border: none;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 0 5px 0 $standard-grey;

    .image {
      border: 1px $standard-grey solid;
    }

    .row {
      height: 100%;
    }

    .time-label {
      font-size: $font-size-small-2;
      font-weight: 700;

      color: $dark-grey;
    }

    .item-teaser {
      white-space: pre-line;
    }
  }

  .filter-bar {
    padding: $space-small-2;
  }

  .filter-item {
    display: inline-block;

    margin-right: $space-small-2;

    cursor: pointer;
    transition: 0.2s;
    text-decoration: none;

    color: $dark-blue;
    border-bottom: 0 solid $card-blue;

    &:hover {
      color: $card-blue;
      border-bottom: 3px solid $card-blue;
    }
  }

  .active-filter {
    border-bottom: 3px solid $card-blue;
  }

  .default-item {
    .card {
      min-height: 270px;
      padding: 30px 160px;

      .date {
        font-size: $font-size-small-2;
        font-weight: 700;

        color: $dark-grey;
      }

      .headline {
        font-size: $font-size-double;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }

  .event-link {
    a {
      font-size: $font-size-small-2;
      font-weight: 700;

      text-decoration: none;

      color: $light-blue;
    }
  }

  .news-link {
    padding-top: $space-small-2;
    padding-bottom: $space-small-2;

    &-text {
      font-size: $font-size-small-2;
      font-weight: 800;

      padding-left: $space-small;

      text-decoration: none;
      text-transform: uppercase;

      color: $light-blue;
    }

    &:hover {
      cursor: pointer;
    }

    a {
      font-size: $font-size-small-2;
      font-weight: 700;

      text-decoration: none;

      color: $light-blue;
    }
  }
}

.getstarted-modal {
  hr {
    margin-top: 50px;
  }

  &-head {
    h1 {
      font-size: $font-size-large;
      font-weight: 500;

      margin-bottom: 0;
    }

    h4 {
      font-size: $font-size-regular;

      margin-bottom: 0;
      padding-top: $space-small-2;
      padding-bottom: $space-xlarge;
    }
  }

  &-input {
    padding-top: $space-xlarge !important;
    padding-left: $space-small;
  }

  &-required-hint {
    font-size: $font-size-small;

    display: inline-block;

    margin-top: 13px;
  }

  &-user {
    margin-top: 27px;

    span {
      font-size: $font-size-small;
    }
  }
}

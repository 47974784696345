.multiple-linkcards {
  .header {
    font-size: $font-size-double;
    font-weight: bold;

    padding: $space-medium 0 0 0;

    color: $dark-blue;
  }

  .subheader {
    font-size: $font-size-medium;
    font-weight: bold;

    color: $dark-blue;
  }

  .categorysubheader {
    font-size: $font-size-regular;
    font-weight: bold;

    margin-bottom: 60px;

    color: $dark-grey;
  }

  .card {
    min-height: 200px;
    margin: $space-small-2;
    padding: $space-large;

    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 $standard-grey;

    h3 {
      margin-bottom: $space-medium;
    }
  }

  .bullets {
    white-space: pre-line;
  }
}

.full-height {
  min-height: 100vh;
}

.full-width {
  width: 100%;
}

.half-height {
  height: 50vh;
}

.third-height {
  height: 33.33vh;
}

.quarter-height {
  height: 25vh;
}

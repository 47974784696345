.btn {
  font-size: $font-size-small-2;
  font-weight: 700;

  height: 50px;
  padding: 0 25px 0 25px;

  text-transform: uppercase;

  color: $white;
  border-color: $light-blue;
  border-radius: 5px;
  background-color: $light-blue;

  &:hover {
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-white {
  border: 2px solid;
  border-color: $white;
}

.btn-blue {
  color: $light-blue;
  border: 2px solid;
  border-color: $light-blue;
  background-color: $white;

  &:hover {
    color: $dark-grey;
  }
}

.btn-blue-transparent {
  color: $light-blue;
  border: 2px solid;
  border-color: $light-blue;
  background-color: transparent;

  &:hover {
    color: $light-blue;
  }
}

.btn-download-list {
  padding-top: 15px;

  color: $light-grey;
  border: 2px solid;
  border-color: $light-blue;
  background-color: $light-blue;

  &.btn-small {
    padding-top: 10px;
  }

  &.add,
  &.select {
    &::before {
      float: left;

      width: 20px;
      margin-top: -2px;
      margin-right: 10px;

      content: url(../../Images/SVG/button_add.svg);
    }
  }

  &.select {
    display: flex;

    padding-top: 0;

    align-items: center;
    justify-content: center;

    &::before {
      height: 17px;
    }
  }

  &.remove {
    &::before {
      float: left;

      width: 20px;
      margin-top: -2px;
      margin-right: 10px;

      content: url(../../Images/SVG/button_remove.svg);
    }
  }

  &.contact {
    display: flex;

    padding-top: 0;

    align-items: center;
    justify-content: center;

    &::before {
      float: left;

      width: 35px;
      height: 38px;
      margin-top: -2px;
      margin-right: 10px;
      margin-left: -5px;

      content: url(../../Images/SVG/mail.svg);
    }
  }
}

.btn-small {
  height: 40px;
}

.btn-negative {
  color: $light-blue;
  border: 2px solid;
  border-color: $light-blue;
  background-color: $light-grey;

  &:hover {
    color: $light-blue;
  }
}

.btn-linkcard {
  font-size: $font-size-small;

  position: absolute;
  bottom: $space-large;

  width: 120px;

  color: $light-blue;
  border: 2px solid;
  background-color: $white;

  &:hover {
    color: $light-blue;
  }
}

.btn-download {
  padding-top: 15px;

  &.btn-small {
    padding-top: 10px;
  }
}

.btn-transparent-white {
  border: 2px solid;
  border-color: $white;
  background: none;
}

.btn-download::before {
  float: left;

  width: 20px;
  margin-top: -2px;
  margin-right: 10px;

  content: url(../../Images/SVG/arrow_download.svg);
}

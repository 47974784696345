.user-list {
  .list-item {
    min-height: 50px;
    border-bottom: 2px solid $light-grey-2;
    padding: 15px 0;

    .actions {
      text-align: end;
      span {
        cursor: pointer;
      }
    }
    .blocking {
      span {
        cursor: pointer;
      }
    }

    .blocked {
      opacity: 0.35;
    }
  }
}

.group-select {
  padding: 5px 10px;
  border-radius: 5px;
}

.create-user-modal {
  .modal-dialog {
    width: auto;
    max-width: none;
    margin: 20px 20px 0;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    min-height: 99vh;
  }

  &-head {
    h4 {
      padding-top: $space-medium;
    }
  }

  &-input {
    flex: 1;

    padding-top: $space-xxlarge;

    border-width: 0 0 2px;
    border-color: $dark-grey;
    outline: 0;

    &:focus {
      border-color: $dark-blue;
    }

    &.error {
      border-color: red;
    }
  }

  .name {
    margin-right: $space-small-2;
  }

  .lastname {
    margin-left: $space-small-2;
  }

  &-info {
    padding-top: $space-small;
    color: $dark-grey;
    font-size: $font-size-small;
    font-weight: 600;
    margin-bottom: $space-xxlarge;
  }

  &-selection {
    padding-top: $space-xxlarge;
    padding-bottom: $space-xlarge;
  }

  &-option {
    font-weight: 700;

    padding-top: $space-large;
  }

  &-required-hint {
    font-size: $font-size-small-2;
    font-weight: 600;

    color: $dark-grey;
  }

  &-confirm {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  button {
    text-transform: uppercase;
  }

  &-submit {
    padding-right: $space-small-2;

    .row {
      padding-right: 24px;
      padding-left: 16px;
    }
  }

  &-error {
    padding-top: $space-small;
    color: red;
    font-size: $font-size-small;
    font-weight: 600;
  }

  .gender {
    label {
      width: 100%;
      font-size: $font-size-small-2;
    }
    input {
      height: 25px;
      width: 20px;
    }
  }
  .groupSelect {
    label {
      width: 100%;
      font-size: $font-size-small-2;
    }
  }
}

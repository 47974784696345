.fonds-performance {
  .performancec_chart {
    height: 560px;

    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      width: 35px;
      height: 35px;

      opacity: 1;
      border-radius: 50%;
      background-color: $light-blue;

      &.active {
        opacity: 1;
        background-color: $light-blue;
      }

      ul li {
        ::before {
          display: none !important;
        }
      }

      ::before {
        display: none;
      }

      &::before {
        display: inline-block;

        width: 18px;
        height: 40px;
        margin-left: 8px;

        content: " ";

        background-image: url("../../../Images/SVG/download_action_negative.svg");
        background-repeat: no-repeat;
        background-size: 20px 40px;
      }
    }
  }
}

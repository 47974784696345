.slider {
  .panel {
    min-height: 400px;

    background-color: $light-grey-2;
  }

  // learn and use: https://reactcommunity.org/react-transition-group/switch-transition
  .my-node-enter {
    opacity: 0;
  }

  .my-node-enter-active {
    transition: opacity 200ms;

    opacity: 1;
  }

  .my-node-exit {
    opacity: 1;
  }

  .my-node-exit-active {
    transition: opacity 200ms;

    opacity: 0;
  }
}

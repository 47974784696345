.icon_teaser {
  .card-type-second {
    background-color: $white;

    .card-image {
      position: absolute;
      top: 0;
      right: 45px;
    }

    .card-label {
      height: 100px;
    }

    .teaser-text {
      font-size: $font-size-small-2;

      margin: 0 30px;
    }
  }
}

// 16px == 1rem

$font-size-small: 0.7rem; // 11px
$font-size-small-2: 0.8rem; // 13px
$font-size-regular: 1rem; // 16px
$font-size-smedium: 1.2rem; // 20px
$font-size-medium: 1.5rem; // 24px
$font-size-double: 2rem; // 32px
$font-size-large: 2.5rem; // 40px
$font-size-xlarge: 3.5rem; // 56px

// spaces
$space-small: 10px; // extra-small
$space-small-2: 15px; // small
$space-medium: 20px; // small
$space-medium-2: 25px; // medium
$space-large: 30px; // large
$space-xlarge: 40px; // extra-large
$space-xxlarge: 60px; // xxlarge
$space-xxxlarge: 80px; // xxxlarge
